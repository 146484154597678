.innerContentInnerPadding {
	padding-right: 22px;
	padding-left: 22px;
}

.topAndBottomPaddingOnMobile {
	padding-top: 12px;
	padding-bottom: 12px;
}

.topAndBottomPaddingOnDesktop {
	padding-top: 8px;
	padding-bottom: 8px;
}
