.newFilterPill {
	margin-top: -8px;
	padding-right: 2px;
	padding-left: 2px;
	margin-left: 21.75px;
	background: linear-gradient(to bottom, transparent 0 50%, white 50% 100%);
}

.innerContentInnerPadding {
	padding-right: 22px;
	padding-left: 22px;
}

.topAndBottomPaddingOnMobile {
	padding-top: 12px;
	padding-bottom: 12px;
}

.topAndBottomPaddingOnDesktop {
	padding-top: 8px;
	padding-bottom: 8px;
}
