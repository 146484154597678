.activeTabStyle {
	border-bottom-color: var(--theme-white);
}

.inactiveTabStyle {
	border-bottom-color: var(--theme-grey-light);
}

@media (--breakpoint-not-small) {
	.themePrimaryNs {
		color: var(--theme-primary);
	}
}
