.expandIcon {
	transition: transform ease-in-out 0.15s;
}

.facetsWrapper {
	overflow: hidden;
	max-height: 0;
}

.facetsWrapperExpanded {
	max-height: none;
}

@media (--breakpoint-large) {
	.facetScrollableValues {
		max-height: 14.75rem;

		/* Scroll indicators. */
		background: linear-gradient(white 30%, rgb(255 255 255 / 0%)), linear-gradient(rgb(255 255 255 / 0%), white 70%) 0 100%,
			radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 16%), rgb(0 0 0 / 0%)),
			radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 16%), rgb(0 0 0 / 0%)) 0 100%;
		background-repeat: no-repeat;
		background-color: white;
		background-size: 100% 2rem, 100% 2rem, 100% 0.75rem, 100% 0.75rem;
		background-attachment: local, local, scroll, scroll;
	}

	.facetsWrapper {
		transition: max-height ease-in-out 0.15s;
	}

	.facetsWrapperExpanded {
		max-height: 18.5rem;
	}
}

.checkbox {
	margin-top: 1px;
	position: relative;
	overflow: hidden;
	background: var(--theme-white);
	border: 1px solid var(--theme-grey);
	border-radius: 2px;
}

.checkbox.checkboxSelected {
	border-color: var(--theme-primary);
}

.checkboxFill {
	opacity: 0;
	height: 1rem;
	width: 1rem;
	color: var(--theme-white);
	background: var(--theme-primary);
	position: relative;
	overflow: hidden;
	transition: opacity 0.05s;
}

.checkboxFill > svg {
	transform: scale(1.3);
}

.checkbox.checkboxSelected .checkboxFill {
	opacity: 1;
}

.checkboxFill::after {
	content: '';
	position: absolute;
	inset: 0;
	background: var(--theme-primary);
	transition: transform 0.12s ease-in;
}

.checkbox.checkboxSelected .checkboxFill::after {
	transform: translateX(100%);
	transition-delay: 0.06s;
}

.facetTooltip :global(.tooltip-section) {
	padding: 10px 0;
	background: none;
}

.facetTooltip :global(.alpha) {
	margin-left: 0 !important;
}

.facetTooltip :global(.col1),
.facetTooltip :global(.col2),
.facetTooltip :global(.col3),
.facetTooltip :global(.col4),
.facetTooltip :global(.col5),
.facetTooltip :global(.col7),
.facetTooltip :global(.span1),
.facetTooltip :global(.span2),
.facetTooltip :global(.span3),
.facetTooltip :global(.span4) {
	float: left;
	margin-left: 3%;
	display: inline;
	position: relative;
	width: 100%;
}

@media (--breakpoint-not-small) {
	.facetTooltip :global(.col1) {
		width: 100%;
	}

	.facetTooltip :global(.col3) {
		width: 31.3%;
	}

	.facetTooltip :global(.col4) {
		width: 22.75%;
	}

	.facetTooltip :global(.col5) {
		width: 17.6%;
	}

	.facetTooltip :global(.col7) {
		width: 11.7%;
	}

	.facetTooltip :global(.span1) {
		width: 18%;
	}

	.facetTooltip :global(.span2) {
		width: 38%;
	}

	.facetTooltip :global(.span4) {
		width: 78%;
	}

	.facetTooltip :global(.col2) {
		width: 48%;

		&:nth-child(2n + 1) {
			clear: left;
		}
	}
}

.facetTooltip :global(.tooltip-img) {
	margin-bottom: 10px;
}
