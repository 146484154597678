.drawer {
	height: 100dvh;
}

.facetDrawerList {
	/* Scroll indicators. */
	background: linear-gradient(white 30%, rgb(255 255 255 / 0%)), linear-gradient(rgb(255 255 255 / 0%), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 16%), rgb(0 0 0 / 0%)),
		radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 16%), rgb(0 0 0 / 0%)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 2rem, 100% 2rem, 100% 0.75rem, 100% 0.75rem;
	background-attachment: local, local, scroll, scroll;
}

.facetDrawerFilters {
	/* Scroll indicators. */
	background: linear-gradient(to right, white 30%, rgb(255 255 255 / 0%)),
		linear-gradient(to left, white 30%, rgb(255 255 255 / 0%)) 100% 0,
		radial-gradient(farthest-side at 0 50%, rgb(0 0 0 / 40%), rgb(0 0 0 / 0%)),
		radial-gradient(farthest-side at 100% 50%, rgb(0 0 0 / 40%), rgb(0 0 0 / 0%)) 100% 0;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 2rem 100%, 2rem 100%, 0.75rem 100%, 0.75rem 100%;
	background-attachment: local, local, scroll, scroll;
}
